/*jshint esversion: 6 */
import {onDocumentReady} from "./lib/ready";
import {createElementFromHTML} from "./lib/html_to_element";

(function () {
  'use strict';
  const NTH_H3 = '3';

  const fill = (url, element, placeholder, where) => {
    fetch(url, {
      method: 'GET',
    })
      .then(response => response.text())
      .then((html) => {
        // const html = response.text();

        if (where === 'before') {
          const el = createElementFromHTML(html);
          placeholder.before(el);
        } else {
          placeholder.innerHTML = html;
        }
      });
  };

  onDocumentReady(() => {
    const salaryCheckEl = document.querySelector('#salarycheck_partial');
    if (salaryCheckEl && salaryCheckEl.dataset.url) {
      fill(
        salaryCheckEl.dataset.url,
        salaryCheckEl,
        document.querySelector(`h3:nth-of-type(${NTH_H3})`),
        'before'
      );
    }

    const questionnaireEl = document.querySelector('#questionnaire_partial');
    if (questionnaireEl && questionnaireEl.dataset.url) {
      fill(
        questionnaireEl.dataset.url,
        questionnaireEl,
        questionnaireEl,
        'inner'
      );
    }
  });
}());
