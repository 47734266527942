/*jshint esversion: 6 */
import {onDocumentReady} from "../lib/ready";
import {createElementFromHTML} from "../lib/html_to_element";

(function () {
  'use strict';

  const springest = {
    springest_api_key: 'c7569df6d52340e72104726b8520d01d8a7eeb5d',
    init: function () {
      const el = document.querySelector('.occupation-detail__springest');

      const isEnabled = () => {
        return window.location.href.toLowerCase().indexOf("loonwijzer.nl") > -1;
      };

      if (window.Springest !== undefined && el !== undefined && isEnabled()) {
        springest.load(el);
      }

    },
    load: function (el) {
      const headingEl = document.createElement('h2');
      headingEl.appendChild(document.createTextNode('Springest opleidingen'));
      el.prepend(headingEl);

      const jobTitle = el.dataset.occupation;

      window.Springest.setApiKey(this.springest_api_key);
      window.Springest.getTrainings(jobTitle, "#springest-jobs", springest.templateIterator);
    },
    templateIterator: function (selector, result) {
      result.trainings.forEach((trainingObj, idx) => {
        trainingObj.description = trainingObj.description.substr(0, 150) + "...";

        const html = window.Springest.template("springest_template", trainingObj);
        const el = createElementFromHTML(html);
        document.querySelector(selector).appendChild(el);
      });
    },
  };

  onDocumentReady(springest.init);
}());
