/*jshint esversion: 6 */

import { onDocumentReady } from "./lib/ready";

let menu = {

  removeClassFromAll: (className) => {
    var elements = document.querySelectorAll('.' + className);
    elements.forEach(function (element) {
      element.classList.remove(className);
    });
  },

  getAllMatchingParents: (element, className) => {
    var matchingParents = [];
    var currentElement = element;

    // Traverse up the DOM tree
    while (currentElement.parentElement) {
      currentElement = currentElement.parentElement;
      if (currentElement.classList.contains(className)) {
        matchingParents.push(currentElement);
      }
    }
    return matchingParents;
  },

  initMenu: () => {
    const menuItems = document.querySelectorAll(".menu .has-submenu > a");
    const menuToggle = document.getElementById("menu-toggle");
    const menuList = document.getElementById("menu-list");

    menuToggle.addEventListener("click", function () {
      menuList.classList.toggle("open");
      menuToggle.classList.toggle("open");
    });

    menuItems.forEach(function (menuItem) {
      menuItem.addEventListener("click", function (event) {
        const parentItem = menuItem.parentElement;
        const inMobileMode =
          document.querySelector(".menu-toggle.open") !== null;
        const hasSubmenu = [...parentItem.children].some((sibling) =>
          sibling.matches("ul.submenu")
        );

        if (hasSubmenu && inMobileMode) {
          event.preventDefault();
          const subOpen = parentItem.classList.contains("submenu-open");

          menu.removeClassFromAll("submenu-open")
          const parentNodes = menu.getAllMatchingParents(menuItem, "has-submenu");

          parentNodes.forEach((el) => {
            el.classList.toggle("submenu-open", !subOpen);
          });
        }
      });
    });
  },
};

// when DOM is loaded:
onDocumentReady(() => {
  const nav = document.querySelectorAll(".main-nav");
  if (nav.length) {
    menu.initMenu();
  }
});
