/*jshint esversion: 6 */
import {onDocumentReady} from "../lib/ready";
import {createElementFromHTML} from "../lib/html_to_element";

(function () {
  'use strict';

  const startEndDate = 'node_start_end_date';

  /**
   * Cluster similar start/end dates together
   */
  const minimumwageStartEndDates = {
    // Mapping between iso date(s) and localized date sentences
    translationMapping: {},
    // Array of node levels, leaves first and root last
    nodeLevels: [2, 1, 0],
    fields: {
      startEndDate: startEndDate,
    },
    // Fields which can contain date(s)
    dateFields: [
      startEndDate,
    ],
    // Date element selector
    selectors: {
      nodeInfo: '.node-info',
      dateInAmounts: 'td > .node-info span',
    },

    init: function (nodeElement) {
      minimumwageStartEndDates.mapDateTranslations(minimumwageStartEndDates.selectors.dateInAmounts);

      // Each node level. E.g. level 2, 1 or 0
      minimumwageStartEndDates.nodeLevels.forEach(function (level) {
        const levelElements = nodeElement.parentNode.querySelectorAll(`div[data-node_depth="${level}"]`);

        levelElements.forEach(function (levelElement) {
          const dateElements = levelElement.querySelectorAll(minimumwageStartEndDates.selectors.dateInAmounts);
          minimumwageStartEndDates.moveDates(levelElement, dateElements);
        });
      });

      minimumwageStartEndDates.moveDates(
        document.querySelector('#content'),
        document.querySelectorAll('.mw__node > .node-info span'),
      )
    },

    /**
     * Create a mapping between iso date(s) and the localized date sentences
     *
     * @param selector - elements which contain dates
     */
    mapDateTranslations: function (selector) {
      document.querySelectorAll(selector).forEach(function (el) {

        minimumwageStartEndDates.dateFields.forEach(function (field) {
          const date = el.dataset[field];
          if (date) {
            if (!minimumwageStartEndDates.translationMapping.hasOwnProperty(field)) {
              minimumwageStartEndDates.translationMapping[field] = {};
            }
          }

          minimumwageStartEndDates.translationMapping[field][date] = el;
        });
      });
    },

    /**
     * Move dates one level up, if the dates in the same element are the same
     *
     * @param element
     * @param dateElements
     */
    moveDates: function (element, dateElements) {
      const datesPerField = {};

      // Each date element, which contains start date or last update
      dateElements.forEach(function (dateElement) {
        minimumwageStartEndDates.dateFields.forEach(function (field) {
          const value = dateElement.dataset[field];
          if (value) {
            if (!datesPerField.hasOwnProperty(field)) {
              datesPerField[field] = new Set();
            }
            datesPerField[field].add(value);
          }
        });
      });

      // If date fields are the same move to top node
      minimumwageStartEndDates.dateFields.forEach(function (field) {
        const dates = datesPerField[field];

        if (dates && dates.size === 1) {
          element.querySelectorAll(`span[data-${field}]`).forEach((el) => {
            el.classList.add('hide');
          });

          const date = dates.entries().next().value[0];

          var levelNodeInfo = element.querySelector(
            `:scope > ${minimumwageStartEndDates.selectors.nodeInfo}`
          );

          const dateEl = minimumwageStartEndDates.translationMapping[field][date];

          if (levelNodeInfo) {
            levelNodeInfo.append(dateEl);
          } else if (field === minimumwageStartEndDates.fields.startEndDate) {
            const startDateText = dateEl.querySelector('span:nth-child(1)').textContent;
            const startDateEl = createElementFromHTML('<li></li>');
            startDateEl.appendChild(document.createTextNode(startDateText));
            document.querySelector('.mw__intro li:nth-child(1)').after(startDateEl);

            const endDate = dateEl.querySelector('span:nth-child(2)')
            if (endDate) {
              const endDateText = endDate.textContent;
              const endDateEl = createElementFromHTML('<li></li>');
              endDateEl.appendChild(document.createTextNode(endDateText));
              document.querySelector('.mw__intro li:nth-child(2)').after(endDateEl);
            }
          }
        }
      });
    }
  };

  onDocumentReady(function () {
    const el = document.querySelector('.mw__node');
    if (el) {
      minimumwageStartEndDates.init(el);
    }
  });
}());
