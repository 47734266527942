/*jshint esversion: 6 */
import List from 'list.js';
import {onDocumentReady} from "./lib/ready";

(function () {
  'use strict';
  function getTextContent(el) {
    if (el) {
      return el.textContent;
    }
  }

  onDocumentReady(() => {
    let shareTitle = getTextContent(document.querySelector('h1'));
    const siteTitle = getTextContent(document.querySelector('.header__title__text a'));

    if (shareTitle !== siteTitle) {
      shareTitle = `${shareTitle} - ${siteTitle}`;
    }

    const elements = document.querySelectorAll('.sharing__social button');
    elements.forEach(button => {
      let shareSubject;
      if (button.dataset.sharer === 'email') {
        shareSubject = shareTitle;
        shareTitle = getTextContent(document.querySelector('.documentDescription'));
      }

      button.dataset.url = window.location.href;

      if ('title' in button.dataset) {
        button.dataset.title = shareTitle;
      }

      if (shareSubject) {
        button.dataset.subject = shareSubject;
      }
    });
  });
}());
