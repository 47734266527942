/*jshint esversion: 6 */
import List from 'list.js';
import {onDocumentReady} from "./lib/ready";

(function () {
  'use strict';

  const pagination = (containerId, searchable) => {
    const options = {
      valueNames: searchable,
      page: 20,
      pagination: true,
    };
    return new List(containerId, options);
  };

  onDocumentReady(() => {
    pagination('content-news', ['effective', 'title']);
    pagination('content-cao', ['effective', 'title']);
    pagination('content-wages', ['description', 'title', 'subtitle']);
  });
}());
