/*jshint esversion: 6 */

import { onDocumentReady } from './lib/ready';

// functions in faq specific namespace
let faq = {
    activateCollapsibilty: () => {
        const faqQuestions = document.querySelectorAll('.faq-question');
        const collapsibles = document.querySelectorAll('.faq-collapsible');
        collapsibles.forEach((collapsible) => {
            collapsible.addEventListener('click', (event) => {
                event.preventDefault();
                const icon = collapsible.firstElementChild;
                if (collapsible.classList.contains('collapsed')) {
                    collapsible.classList.remove('collapsed');
                    icon.classList.remove('fa-angles-down');
                    icon.classList.add('fa-angles-up');
                    faqQuestions.forEach((question) => {
                        const answer = question.nextElementSibling;
                        answer.classList.add('show');
                    });
                } else {
                    collapsible.classList.add('collapsed');
                    icon.classList.remove('fa-angles-up');
                    icon.classList.add('fa-angles-down');

                    faqQuestions.forEach((question) => {
                        const answer = question.nextElementSibling;
                        answer.classList.remove('show');
                    });
                }
            });
        });
    },
    createAccordion: (faqQuestions) => {
        faqQuestions.forEach((question) => {
            question.addEventListener('click', () => {
                const answer = question.nextElementSibling;
                if (answer.classList.contains('show')) {
                    answer.classList.remove('show');
                } else {
                    answer.classList.add('show');
                }
                const icon = question.firstElementChild;
                if (icon.classList.contains('fa-caret-down')) {
                    icon.classList.remove('fa-caret-down');
                    icon.classList.add('fa-caret-up');
                } else {
                    icon.classList.remove('fa-caret-up');
                    icon.classList.add('fa-caret-down');
                }
            });
        });
    },
};

// when DOM is loaded:
onDocumentReady(() => {
    const faqQuestions = document.querySelectorAll('.faq-question');
    if (faqQuestions.length) {
        faq.createAccordion(faqQuestions);
        faq.activateCollapsibilty();
    }
});
