/*jshint esversion: 6 */

/**
 * Cost of Living mini survey
 */
(function () {
  'use strict';

  function getScriptEl(url) {
    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('src', url);
    scriptEl.setAttribute('type', 'module');
    return scriptEl;
  }

  const livingWages = {
    locale: () => {
      const lang = document.querySelector('html').lang;
      const splittedLang = lang.split('-');

      if (splittedLang.length === 2) {
        return splittedLang[0] + '_' + splittedLang[1].toUpperCase();
      }
    }
  };

  let url;
  if (window.location.hostname === 'localhost') {
    // url = 'http://localhost:4242/src/main.js';
    url = 'https://frontends.wageindicator.org/col_mini_survey_acc/cost-of-living-mini-survey.es.js';
  } else if (window.location.hostname === 'staging.wageindicator.org') {
    url = 'https://frontends.wageindicator.org/col_mini_survey_acc/col-mini-survey-main.es.js';
  } else {
    url = 'https://frontends.wageindicator.org/col_mini_survey/cost-of-living-mini-survey.es.js';
  }

  const locale = livingWages.locale();

  if (!locale) {
    return;
  }

  const miniSurveyHtml = `
<cost-of-living-mini-survey locale="${locale}">
  <div class="spinner-container">
      <span class="spinner"></span>
  </div>
</cost-of-living-mini-survey>
  `;

  const fullSurveyHtml = `
<cost-of-living-full-survey locale="${locale}">
  <div class="spinner-container">
      <span class="spinner"></span>
  </div>
</cost-of-living-full-survey>
  `;


  const teaser = document.querySelector('.main__aside .col-short-survey-init');
  if (teaser) {
    teaser.innerHTML = miniSurveyHtml;
  } else if (teaser) {
    teaser.closest('article').style = 'display: none';
  }

  const container = document.querySelector('.livingwage-survey-container');
  if (container) {
    container.innerHTML = fullSurveyHtml;
  }

  if (teaser || container) {
    document.body.appendChild(getScriptEl(url));
  }

}());
