/*jshint esversion: 6 */
import {onDocumentReady} from "./lib/ready";

(function () {
  'use strict';

  onDocumentReady(() => {
    const el = document.querySelector('.show-more-teasers');

    if (el) {
      el.addEventListener('click', (event) => {
        event.preventDefault();

        const teasers = document.querySelectorAll('.main__teasers > *');
        teasers.forEach(teaser => {
          teaser.style.display = "block";
        });

        el.remove();
      });
    }
  });
}());
