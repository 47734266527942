/*jshint esversion: 6 */

import {onDocumentReady} from "./lib/ready";

(function () {
  'use strict';

  onDocumentReady(function () {
    const matchTypeDocument = function () {
      if (document.querySelector('body.portaltype-document') !== null) {
        return true;
      }
      return false;
    };

    const getFirstParagraph = function () {
      return document.querySelector('#content-core #parent-fieldname-text p');
    };

    const getAdvertisement = function () {
      return document.querySelector('.in_article_ad');
    };

    if (matchTypeDocument()) {
      const firstParagraph = getFirstParagraph();
      const advertisement = getAdvertisement();

      if (firstParagraph && advertisement) {
        // Insert script for ad in DOM, however the javascript is not yet executed
        firstParagraph.insertAdjacentHTML('afterend',
          `<div id="in-article-ad">${advertisement.innerText}</div>`);

        const script = document.querySelector('#in-article-ad script');

        // Inject script tag and execute
        if (script) {
          const ad = document.createElement('script');
          ad.type = 'text/javascript';

          if (script.src) {
            ad.src = script.src;
          }
          if (script.innerHTML) {
            ad.innerHTML = script.innerHTML;
          }

          firstParagraph.appendChild(ad);
          script.remove();
        }
      }
    }
  });

}());
