/*
 * Originally from plone, which uses jquery and whatnot. Rewritten using chantgpt and
 * some own fixes. Seems to work
 */

import { onDocumentReady } from "./lib/ready";
import { isLoggedIn } from "./lib/utils";

class AutoTOC {
  constructor(options) {
    this.options = Object.assign({
      section: 'section',
      levels: 'h1,h2,h3',
      IDPrefix: 'autotoc-item-',
      classTOCName: 'autotoc-nav',
      classSectionName: 'autotoc-section',
      classLevelPrefixName: 'autotoc-level-',
      classActiveName: 'active',
      scrollDuration: 'slow',
      scrollEasing: 'swing',
      prependTo: null,
      appendTo: null,
      className: null
    }, options);

    this.init();
  }

  init() {

    const autoTocEl = document.querySelector('.pat-autotoc');

    if (autoTocEl) {

      this.toc = document.createElement('nav');
      this.toc.classList.add(this.options.classTOCName);

      if (this.options.prependTo) {
        document.querySelector(this.options.prependTo).prepend(this.toc);
      } else if (this.options.appendTo) {
        document.querySelector(this.options.appendTo).append(this.toc);
      } else {
        document.querySelector('.pat-autotoc').prepend(this.toc);
      }

      if (this.options.className) {
        autoTocEl.classList.add(this.options.className);
      }

      autoTocEl.querySelectorAll(this.options.section).forEach(section => {
        section.classList.add(this.options.classSectionName);
      });

      const asTabs = autoTocEl.classList.contains('autotabs');
      let activeId = null;

      autoTocEl.querySelectorAll(this.options.levels).forEach((level, i) => {
        let id = level.id || level.closest(this.options.section).id;
        if (!id || document.getElementById(id)) {
          id = `${this.options.IDPrefix}${i}`;
        }
        if (window.location.hash === `#${id}`) {
          activeId = id;
        }
        if (activeId === null && level.classList.contains(this.options.classActiveName)) {
          activeId = id;
        }
        level.dataset.navref = id;

        const link = document.createElement('a');
        link.id = id;
        link.href = `#${id}`;
        link.textContent = level.textContent;
        link.classList.add(`${this.options.classLevelPrefixName}${this.getLevel(level)}`);
        link.addEventListener('click', (e) => this.onClick(e, level, asTabs));
        this.toc.appendChild(link);

        level.dataset.autotocTriggerId = id;
      });

      if (activeId) {
        document.getElementById(activeId).click();
      }
    }
  }

  getLevel(el) {
    let elementLevel = 0;
    this.options.levels.split(',').forEach((levelSelector, level) => {
      if (el.matches(levelSelector)) {
        elementLevel = level + 1;
      }
    });
    return elementLevel;
  }

  onClick(e, level, asTabs) {
    e.preventDefault();
    const target = e.target;
    this.toc.querySelectorAll(`.${this.options.classActiveName}`).forEach(el => el.classList.remove(this.options.classActiveName));
    // document.querySelectorAll(`.${this.options.classActiveName}`).forEach(el => el.classList.remove(this.options.classActiveName));
    target.classList.add(this.options.classActiveName);
    level.closest(this.options.section).classList.add(this.options.classActiveName);

    if (!asTabs) {
      window.scrollTo({
        top: level.offsetTop,
        behavior: 'smooth'
      });
    }

    if (!e.detail || !e.detail.skipHash) {
      if (window.history && window.history.pushState) {
        window.history.pushState({}, '', `#${target.id}`);
      }
    }

    target.dispatchEvent(new Event('clicked'));
  }
}

// when DOM is loaded:
onDocumentReady(() => {
  // plone already has the autotoc in it's own patternslib for logged in
  if (!isLoggedIn()) {
    new AutoTOC({});
  }
});
