/*jshint esversion: 6 */
import List from 'list.js';
import {onDocumentReady} from "./lib/ready";

(function () {
  'use strict';

  const responsiveIframe = (el) => {
    const parent = el.parentNode.parentNode;
    const width = parent.getBoundingClientRect().width;

    let src;
    if (width >= 660) {
      src = el.dataset.desktopSrc;
    } else {
      src = el.dataset.mobileSrc;
    }
    el.setAttribute('src', src);
  };

  onDocumentReady(() => {
    const el = document.querySelector('iframe.raet-device-switch');
    if (el) {
      responsiveIframe(el);
    }
  });
}());
