/*jshint esversion: 6 */
/*

  Note: this is a pretty specific solution for a specific problem!

  Creates a dropdown from an anchorlist where only the selected
  anchors will be shown. Example:

  <div id="anchorselector-linklist">
    <li><a href="#anchor1">one</a></li>
    <li><a href="#anchor2">two</a></li>
  </div>

  This script assumes a table to exist with class anchorselector:

  <table class="anchorselect">
    <tr>
      <td>
        <a id="anchor1"/>
        <p>...</p>
      </td>
    </tr>
    <tr>
      <td>
        <a id="anchor2"/>
        <p>...</p>
      </td>
    </tr>
  </table>
*/

import SlimSelect from 'slim-select';
import {onDocumentReady} from "./lib/ready";

// functions in anchorselect specific namespace
let anchorselect = {

  // used in blockingmodal.js
  blockingModalName: "bmlwModal",
  bmUpdateEvent: new CustomEvent("blockingModalUpdate", {"detail": {"external": true}}),

  insertAfter: (referenceNode, newNode) => {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  },

  toggleAnchor: () => {
    let anchorSelect = document.getElementById("anchorselector");
    let selectedOption = anchorSelect.options[anchorSelect.selectedIndex];

    if (selectedOption.value) {
      let anchor = document.querySelector(selectedOption.value);
      if (anchor) {
        const visibles = document.querySelectorAll(".anchorselect tr.visible");
        visibles.forEach(element => {
          element.classList.toggle("visible");
        })

        const parentElement = anchor.closest("tr");
        parentElement.classList.toggle("visible");

        // hack to get next row visible as well
        const nextElement = parentElement.nextElementSibling;
        nextElement.classList.toggle("visible");
      }
    }
  },

};

// when DOM is loaded:
onDocumentReady(() => {
  const modal = document.getElementById(anchorselect.blockingModalName);

  let linkList = document.getElementById("anchorselector-linklist");

  if (linkList !== null) {
    let anchorSelector = document.createElement("select");
    anchorSelector.id = "anchorselector";
    anchorselect.insertAfter(linkList, anchorSelector);

    let links = linkList.getElementsByTagName("a");
    for (let i = 0; i < links.length; i++) {
      let option = document.createElement("option");
      option.value = links[i].hash;
      option.text = links[i].textContent;
      anchorSelector.appendChild(option);
    }

    anchorSelector.addEventListener("change", (event) => {
      anchorselect.toggleAnchor();

      if (modal !== null) {
        modal.dispatchEvent(anchorselect.bmUpdateEvent);
      }
    });

    let firstrow = document.querySelector(".anchorselect tbody tr:first-child");
    if (firstrow !== null) {
      firstrow.classList.toggle("visible");

      // hack to get next row visible as well
      let nextRow = firstrow.nextElementSibling;
      if (nextRow !== null) {
        nextRow.classList.toggle("visible");
      }
    }

    // Add class to body for specific slim select styling
    document.body.classList.add("anchorselect");

    // make it searchable
    new SlimSelect({select: "#anchorselector"});
  }
});
