/*jshint esversion: 6 */
import {onDocumentReady} from "../lib/ready";

(function () {
  'use strict';

  const talentVacancies = {
    limit: 8,
    init: function () {
      const {lang} = document.querySelector('html');
      const vacancyEl = document.querySelector('.occupationlanding__vacancies.neuvoo');

      // Disable on FI; Does not work, domain needs to be directly on ciney.
      if (vacancyEl && lang !== 'fi-fi') {
        talentVacancies.fetch(vacancyEl, lang);
      }
    },
    fetch: function (vacancyEl, lang) {
      const country = lang.split('-').splice(-1)[0];

      let jobTitle = document.querySelector('.template-ol_detail_view h1').textContent;

      let url;
      if (window.location.hostname === 'localhost') {
        url = document.querySelector('#breadcrumbs-home a').href;
      } else {
        url = window.location.protocol + '//' + window.location.hostname;
      }

      url += "/neuvoo_proxy?&contenttype=all&format=json&publisher=fd90afcd&cpcfloor=1&subid=10101";
      url += '&country=' + country;
      url += '&limit=' + talentVacancies.limit;
      url += '&useragent=' + encodeURIComponent(navigator.userAgent);

      if (jobTitle) {
        jobTitle = encodeURIComponent(jobTitle);
        // Hack to handle job names with more than one word
        jobTitle = jobTitle.replace("%20", "&");
        url += "&k=" + jobTitle;
      }

      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
        },
      })
        .then(response => response.json())
        .then((response) => {
          talentVacancies.populateVacancies(lang, vacancyEl, response);
        });
    },
    populateVacancies: function (lang, el, response) {
      const vacancies = response.results || response.data.results || [];

      if (vacancies.length === 0) {
        return;
      }
      const listEl = el.querySelector('ul');

      vacancies.forEach((vacancy, index) => {
        const mousedown = vacancy.onmousedown.replace('"', "'");
        const anchorEl = document.createElement('a');
        anchorEl.setAttribute('href', vacancy.url);
        anchorEl.setAttribute('onmousedown', mousedown);

        const title = vacancy.jobtitle;
        const city = vacancy.city;
        let text;
        if (city && title.indexOf(city) === -1 && city !== 'null') {
          text = document.createTextNode(`${title} (${city})`);
        } else {
          text = document.createTextNode(title);
        }

        const spanEl = document.createElement('span');
        spanEl.appendChild(text);
        anchorEl.appendChild(spanEl);

        const liEl = document.createElement('li');
        liEl.appendChild(anchorEl);
        listEl.appendChild(liEl);
      });

      let section = document.querySelector('.occupationlanding__vacancies.neuvoo');
      let targetEl;
      if (lang === 'nl-nl') {
        targetEl = document.querySelector('.occupation-detail__springest');
      } else if (lang === 'nl-be') {
        targetEl = document.querySelector('.occupationlanding__survey');
      }

      if (targetEl) {
        targetEl.insertAdjacentElement('afterend', section);
      }

      section.style.display = 'block';
    }
  };

  onDocumentReady(talentVacancies.init);
}());
