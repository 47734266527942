/*jshint esversion: 6 */

(function () {
  'use strict';

  const ENABLED_KEY = 'talent-overlay-v1';

  const talentOverlay = {

    init: function () {
      if (window.talentModalLoaded === true) {
        return;
      }

      if (window.location.hash.indexOf('talent-overlay') !== -1) {
        localStorage.removeItem(ENABLED_KEY);
      }

      if (!localStorage.getItem(ENABLED_KEY)) {
        talentOverlay.fetch();

        localStorage.setItem(ENABLED_KEY, 'true');
        window.talentModalLoaded = true;
      }
    },

    fetch: function () {
      const baseUrl = document.querySelector('.header__title a').href;
      const url = `${baseUrl}/@@talent_com_overlay`;

      const xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          talentOverlay.show(this.responseText);
        }
      };

      xhttp.open("GET", url, true);
      xhttp.send();
    },

    show: function (overlayHtml) {
      const containerEl = document.createElement('div');
      containerEl.innerHTML = overlayHtml;
      document.querySelector('body').insertAdjacentElement('beforeend', containerEl);

      require(['pat-registry'], function (registry) {
        // Re-init Plone mockup patterns for the injected HTML
        registry.scan(containerEl);
        // Trigger opening of overlay
        document.querySelector("#modal-link-talent-com").click();

        const el = document.querySelector('.plone-modal-wrapper')
        el.setAttribute('id', 'talent-overlay')

        const submitButton = document.querySelector('.plone-modal-body input[name="subscribe"]');
        submitButton.addEventListener('click', function (event) {
          const form = event.target.closest('form');

          if (form.checkValidity()) {
            event.preventDefault();
            talentOverlay.post(form);
          }
        }, false);

        if (document.querySelector('body').classList.contains('template-ol_detail_view')) {
          const jobTitle = document.querySelector('.main h1').textContent;
          const input = el.querySelector('#career-field');
          input.value = jobTitle;
        }
      });
    },

    post: function (formEl) {
      fetch(formEl.getAttribute('action'), {
        method: formEl.getAttribute('method'),
        body: new FormData(formEl)
      }).then(res => res.json())
        .then(function (data) {
          document.querySelector('.plone-modal-close').click();

          const navEl = document.querySelector('nav.main-nav');

          let messageEl;
          if (data.response.toLowerCase() === 'registered') {
            messageEl = document.querySelector('#talent_statusmessage__subscribed');
          } else {
            messageEl = document.querySelector('#talent_statusmessage__error');
          }

          messageEl.style.display = "block";
          navEl.insertAdjacentElement('afterend', messageEl);
        });
      event.preventDefault();
    }
  };

  window.loadTalentOverlay = talentOverlay.init;

}());
