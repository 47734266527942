/*jshint esversion: 6 */
import List from 'list.js';
import {onDocumentReady} from "./lib/ready";

(function () {
  'use strict';

  /*
   * Creates a 'snackbar' messenger using the HTML markup below.
   *
   * <div class="wi-snackbar"
   *      data-cookie="cookie name"
   *      data-color="hex font color"
   *      data-bgcolor="hex background color"
   *      data-bottom="distance from bottom"
   *      data-height="height of the snackbar"
   *      data-minwidth="minimal width of the snackbar">
   *
   *   Lorem ipsum dolor sit amet,
   * </div>
   *
   * When the data-cookie attribute is set, a cookie with the given name
   * will be set which causes the snackbar not to be shown for 28 days.
   */
onDocumentReady(function () {

    let snackbars = document.getElementsByClassName("wi-snackbar");

    function createCookie(name, value, days) {
      let expires = "";
      if (days) {
        let date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1e3);
        expires = "; expires=" + date.toGMTString();
      }
      document.cookie = name + "=" + value + expires + "; path=/";
    }

    function readCookie(name) {
      let nameEQ = name + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }

    function eraseCookie(name) {
      createCookie(name, "", -1);
    }

    function removeSnackbar(snackbar) {
      snackbar.classList.add("hide");
      setTimeout(function(){
        snackbar.classList.remove("show");
      }, 3000);
    }

    // temporary fix to disable snackbars in all sites
    let inactiveSnackbars = ['cta_corona', 'cta_garmin'];
    for (let i = 0; i < snackbars.length; i++) {

      if (inactiveSnackbars.includes(snackbars[i].getAttribute("data-cookie"))) {
        // console.log('Skipping inactive snackbar ' + snackbars[i].getAttribute("data-cookie"));
        continue;
      }

      let closeButton = snackbars[i]
        .getElementsByTagName("button")[0];

      if (!closeButton) {
        closeButton = document.createElement("button");
        closeButton.setAttribute("type", "button");
        closeButton.setAttribute("class", "close");
        closeButton.innerHTML = "×";
        snackbars[i].appendChild(closeButton);
      }

      closeButton.addEventListener("click", function (event) {
        event.preventDefault();
        if (snackbars[i].getAttribute("data-cookie")) {
          createCookie(snackbars[i].getAttribute("data-cookie"), 'shown', 28);
        }
        removeSnackbar(snackbars[i]);
      });

      setTimeout(function(){
        snackbars[i].classList.add("hide");
        removeSnackbar(snackbars[i]);
      }, 7000);

      let style = "";
      if (snackbars[i].getAttribute("data-bgcolor")) {
        style += "background-color:" + snackbars[i].getAttribute("data-bgcolor") + ";";
      }
      if (snackbars[i].getAttribute("data-color")) {
        style += "color:" + snackbars[i].getAttribute("data-color") + ";";
      }
      if (snackbars[i].getAttribute("data-bottom")) {
        style += "bottom:" + snackbars[i].getAttribute("data-bottom") + ";";
      }
      if (snackbars[i].getAttribute("data-height")) {
        style += "height:" + snackbars[i].getAttribute("data-height") + ";";
      }
      if (snackbars[i].getAttribute("data-minwidth")) {
        style += "min-width:" + snackbars[i].getAttribute("data-minwidth") + ";";
      }
      if (style) {
        snackbars[i].setAttribute("style", style);
      }

      if (snackbars[i].getAttribute("data-cookie")) {
        if (!readCookie(snackbars[i].getAttribute("data-cookie"))) {
          snackbars[i].classList.add("show");
        } else {
          snackbars[i].setAttribute("style", "display: none");
        }
      } else {
        snackbars[i].classList.add("show");
      }
    }
  });
}());
