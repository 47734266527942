/*jshint esversion: 6 */
import TableFilter from 'tablefilter';
import SlimSelect from 'slim-select';


/**
 * Filtering on html tables
 * https://www.npmjs.com/package/tablefilter
 *
 * Version 0.5.43 is used, newer versions (0.6.x / 0.7x) do not
 * work with this plone / webpack set-up.
 *
 *  Example: https://codepen.io/kcleong/pen/LYRZMpW
 */
(function () {
  'use strict';
  // require(['tablefilter', 'slim-select'], function (TableFilter, SlimSelect) {


    const filter = {

      css_selector: 'table.filterable',

      getBaseConfig() {
        return {
          base_path: 'https://unpkg.com/tablefilter@0.5.43/dist/tablefilter/',
          images_path: 'https://unpkg.com/tablefilter@0.5.43/dist/tablefilter/style/themes/',
          alternate_rows: true,
          rows_counter: false,
          btn_reset: true,
          loader: true,
          mark_active_columns: true,
          highlight_keywords: true,
          no_results_message: true,
          help_instructions: null,
          extensions: [{
            name: 'sort',
          }]
        };
      },
      getConfig(tableEl) {

        let tableConfig = {
          col_types: [],
          col_widths: [],
        };

        let idx = 0;
        tableEl.querySelectorAll('tr:first-child td:not(.nofilter)').forEach(el => {
          tableConfig[`col_${idx}`] = 'select';
          tableConfig.col_types.push('string');
          tableConfig.col_widths.push(`${el.offsetWidth}px`);

          idx += 1;
        });

        return Object.assign(this.getBaseConfig(), tableConfig);
      },

      init(tableEl) {
        // Get config and column widths
        const config = this.getConfig(tableEl);

        // Clean inline styles which are set on the table by tinymce
        tableEl.removeAttribute('style');
        tableEl.querySelectorAll('[style]')
          .forEach(el => el.removeAttribute('style'));

        // Enable table filter
        let tf = new TableFilter(tableEl, config);
        tf.init();

        // Enable slim select for drop downs
        document.querySelectorAll(`${this.css_selector} select`).forEach(el => {
          new SlimSelect({select: el});
        });

      },
    };


    document.querySelectorAll(filter.css_selector).forEach(table => {
      filter.init(table);
    });

  // });

}());
