console.log("⭐⭐⭐ WageIndicator Vite Theme ⭐⭐⭐");

import "./stylesheets/main.scss";

import "./scripts/vacancy_feeds/springest.js";
import "./scripts/vacancy_feeds/talent_com.js";
import "./scripts/vacancy_feeds/talent_com_overlay.js";

import "./scripts/ads.js";
import "./scripts/anchorselect.js";
import "./scripts/caowijzer.js";
import "./scripts/factoryreport.js";
import "./scripts/faq.js";
import "./scripts/fontawesome.js";
import "./scripts/general.js";
import "./scripts/generatedcontent.js";
import "./scripts/livingwage.js";
import "./scripts/menu.js";
import "./scripts/minimum_wages/archive_date_picker.js";
import "./scripts/minimum_wages/start_end_dates.js";
import "./scripts/raetiframe.js";
import "sharer.js/sharer.js";
import "./scripts/share.js";
import "./scripts/snackbar.js";
import "./scripts/tablefiltering.js";
import "./scripts/autotoc.js";

// window.require = undefined;  // Fix @@search

/**
 * Fix overrides for `String.prototype.startswith`
 * in jquery-ext.js (plone.staticresources == 1.4.3) this function is overridden.
 * However, startswith is broken in certain circumstances. For example
 * `'666'.startWith('.')` returns true.
 *
 * Below the original startsWith function is restores.
 */

document.addEventListener("DOMContentLoaded", function () {
  const iframe = document.createElement("iframe");
  iframe.src = "about:blank";
  document.body.appendChild(iframe);
  String.prototype.endsWith = iframe.contentWindow.String.prototype.endsWith;
  String.prototype.startsWith =
    iframe.contentWindow.String.prototype.startsWith;
  document.body.removeChild(iframe);
});
