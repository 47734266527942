/*jshint esversion: 6 */
import {onDocumentReady} from "../lib/ready";
import {createElementFromHTML} from "../lib/html_to_element";

(function () {
  'use strict';

  const archiveDatePicker = {
    year_selector: '#archive_start_year',
    month_selector: '#archive_start_month',

    enable: function () {
      const year = archiveDatePicker.getSelectedYear();
      archiveDatePicker.disableInactiveMonths(year);
    },
    eventHandler: function () {
      const el = document.querySelector(archiveDatePicker.year_selector);
      el.addEventListener('change', archiveDatePicker.enable);
    },
    getSelectedYear: function () {
      const el = document.querySelector(archiveDatePicker.year_selector);
      return el.value;
    },
    disableInactiveMonths: function (year) {
      const months = document.querySelector(archiveDatePicker.month_selector).options;
      const monthsDisabled = document.querySelectorAll(`${archiveDatePicker.month_selector} .disabled-${year}`);

      // Remove all dynamic attributes
      Array.from(months).forEach(function (el) {
        el.removeAttribute('disabled');
        el.removeAttribute('selected');
      });

      // Mark first non-disabled month as selected
      let selectedEnabled = false;
      if (monthsDisabled.length > 0) {
        const lastEl = monthsDisabled[monthsDisabled.length - 1];
        if (lastEl.nextElementSibling) {
          selectedEnabled = true;
          lastEl.nextElementSibling.setAttribute('selected', true);
        }
      }

      // Mark first month as selected when this is not done yet
      if (!selectedEnabled) {
        months[0].setAttribute('selected', true);
      }

      // Mark disabled months as disabled
      Array.from(monthsDisabled).forEach(function (el) {
        el.setAttribute('disabled', true);
      });
    }
  };

  onDocumentReady(function () {
    const el = document.querySelector('#mw-archive-picker');
    if (el) {
      archiveDatePicker.enable();
      archiveDatePicker.eventHandler();
    }
  });
}());
