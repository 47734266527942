/* Include all the FA icons that are in use. This keeps the bndle size low */

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import {
  faEdit, faNewspaper, faCaretSquareDown, faTimesCircle
} from '@fortawesome/free-regular-svg-icons';
import {
  faAt, faBullhorn, faCrop, faAnglesDown, faAnglesUp, faEnvelopeSquare, faLanguage, faCaretDown, faCaretUp, faMagnifyingGlass, faIndustry,
} from '@fortawesome/free-solid-svg-icons';
import {
  faFacebookF, faTwitter, faLinkedin, faFacebookSquare, faSquareXTwitter, faInstagramSquare, faYoutubeSquare
} from '@fortawesome/free-brands-svg-icons';

// Add the icons to the library so they can be used in the DOM
library.add(
  faEdit, faNewspaper, faCaretSquareDown, faTimesCircle, // Regular
  faAt, faBullhorn, faCrop, faAnglesDown, faAnglesUp, faEnvelopeSquare, faLanguage, faCaretDown, faCaretUp, faMagnifyingGlass, faIndustry, // Solid
  faFacebookF, faTwitter, faLinkedin, faFacebookSquare, faSquareXTwitter, faInstagramSquare, faYoutubeSquare, // Brands
);

// Tell FontAwesome to watch the DOM and add the icons
dom.watch();
